import React, { useEffect, useState } from "react";
import { db } from "../firebase-config";
import { collection, getDocs } from "firebase/firestore";
import { arrayUnion, updateDoc, doc } from "firebase/firestore";
import "./musiclistmodule.css";

const MusicList = () => {
  const [musicList, setMusicList] = useState([]);
  const [playList, setPlayList] = useState([]);

  const getData = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "music"));
      setMusicList([]);
      querySnapshot.forEach((doc) => {
        setMusicList((oldArray) => [...oldArray, doc.data()]);
      });
      console.log("Did request!");
    } catch (error) {
      console.log(error);
    }
  };

  const getPlaylist = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "playlist"));
      setPlayList([]);
      querySnapshot.forEach((doc) => {
        setPlayList((oldArray) => [...oldArray, doc.data()]);
      });
      console.log("Did request!");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
    getPlaylist();
  }, []);

  const addCurrentToPlayList = async (songName, realID) => {
    const currentPlayList = document.getElementById(songName).value;
    await updateDoc(doc(db, "playlist", currentPlayList), {
      songs: arrayUnion(realID),
    });
  };

  return (
    <div className="MusicList">
      <select defaultValue={"all"} className="select-default">
        {playList.map((play) => {
          return (
            <option key={play.playListName} value={play.playListName}>
              {play.playListName}
            </option>
          );
        })}
        <option value="all">All</option>
      </select>
      {musicList.map((music) => {
        return (
          <div className="musicbox" key={music.songName}>
            <img
              src={music.songCover}
              alt={music.songName}
              className="musicimg"
            />
            <div className="musictext">
              <div>{music.songName}</div>
              <div>{music.songAuthor}</div>
              <div>{music.songTime}</div>
            </div>
            <div className="select-playlist">
              <select
                defaultValue={"all"}
                id={music.songName}
                className="select-default mini"
              >
                {playList.map((play) => {
                  return (
                    <option key={play.playListName} value={play.playListName}>
                      {play.playListName}
                    </option>
                  );
                })}
                <option value="all">All</option>
              </select>
              <button
                className="buttonUI"
                onClick={() => {
                  addCurrentToPlayList(music.songName, music.realID);
                }}
              >
                ✔️
              </button>
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default MusicList;
