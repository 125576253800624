import React, { useState, useEffect } from "react";
import {
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import { auth } from "../firebase-config";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./ProfileModule.css";

function Profile() {
  const [toggleLogin, setToggleLogin] = useState(true);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [loggedInEmail, setLoggedInEmail] = useState("");
  const [loggedInUID, setLoggedInUID] = useState("");

  const onLogin = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        notifySuccess("Login successful!");
        console.log(user);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        notifyError();
        console.log(errorCode, errorMessage);
      });
  };

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        setToggleLogin(false);
        setLoggedInEmail(user.email);
        setLoggedInUID(user.uid);
        // ...
      } else {
        // User is signed out
        // ...
        console.log("user is logged out");
        setToggleLogin(true);
      }
    });
  }, []);

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        notifySuccess("Logout successfully!");
      })
      .catch((error) => {
        // An error happened.
      });
  };

  const notifyError = (errorMSG) =>
    toast.error("❌ Error! " + errorMSG, {
      icon: false,
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  const notifySuccess = (messageSuccess) =>
    toast.success("✔️ " + messageSuccess, {
      icon: false,
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  return (
    <div className="Profile">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <main>
        <section>
          {toggleLogin && (
            <div>
              <p> Login </p>

              <form>
                <div>
                  <label htmlFor="email-address">Email address</label>
                  <input
                    id="email-address"
                    name="email"
                    type="email"
                    required
                    placeholder="Email address"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                <div>
                  <label htmlFor="password">Password</label>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    required
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <br />
                <div>
                  <button className="buttonUI" onClick={onLogin}>
                    Login
                  </button>
                </div>
              </form>
            </div>
          )}
        </section>
        {toggleLogin ? (
          false
        ) : (
          <div>
            <h2>{loggedInEmail}</h2>
            <h2 className="uid-hide">{loggedInUID}</h2>
            <br />
            <button className="buttonUI" onClick={handleLogout}>
              Logout
            </button>
          </div>
        )}
      </main>
    </div>
  );
}

export default Profile;
