// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBrgO81V9CtnZ1JI18ObrE1U6o64gdNx_o",
  authDomain: "music-player-8ac3b.firebaseapp.com",
  projectId: "music-player-8ac3b",
  storageBucket: "music-player-8ac3b.appspot.com",
  messagingSenderId: "687500407800",
  appId: "1:687500407800:web:d251a1656e7c33890a1c0d",
  measurementId: "G-XV2D0D5QLK",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const db = getFirestore(app);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
export const storage = getStorage(app);
