import React, { useState } from "react";
import { setDoc, doc } from "firebase/firestore";
import { db } from "../firebase-config";
import { storage } from "../firebase-config";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./CreateModule.css";

import MusicList from "../components/MusicList";

import place500 from "../img/500x500.png";

function Create() {
  const [songName, setSongName] = useState("");
  const [songAuthor, setSongAuthor] = useState("");
  const [songTime, setSongTime] = useState("");

  // State to store uploaded file
  const [file, setFile] = useState(""); // progress
  const [imgcover, setImgCover] = useState(""); // cover

  function handleChange(event) {
    //Audio Preview
    const cfile = event.target.files[0];
    let audio = document.getElementById("audio-preview");
    let reader = new FileReader();

    reader.readAsDataURL(cfile);
    reader.addEventListener("load", function () {
      audio.src = reader.result;
    });
    //setDuration
    setTimeout(function () {
      const timeWithPoint = document
        .getElementById("audio-preview")
        .duration.toString();
      const timeByReader = timeWithPoint.replace(/\./g, "");
      if (songTime.length === 0) {
        document.getElementById("inputTime").value = millisToMinutesAndSeconds(
          Number(timeByReader)
        );
        setSongTime(millisToMinutesAndSeconds(Number(timeByReader)));
      }
    }, 1500);
    //setFile for Upload & and setName
    setFile(event.target.files[0]);
    const uploadNameByFile = event.target.files[0].name.replace(".mp3", "");
    if (songName.length === 0) {
      document.getElementById("inputName").value = uploadNameByFile;
      setSongName(uploadNameByFile);
    }
  }
  function handleChangeCover(event) {
    //Audio Preview
    const ifile = event.target.files[0];
    let imageFile = document.getElementById("image-preview");
    let reader = new FileReader();

    reader.readAsDataURL(ifile);
    reader.addEventListener("load", function () {
      imageFile.src = reader.result;
    });
    setImgCover(ifile);
  }

  const millisToMinutesAndSeconds = (millis) => {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return seconds === 60
      ? minutes + 1 + ":00"
      : minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
  };

  const uploadMusic = async () => {
    if (!file) {
      alert("Please select a file first!");
    }
    const storageRef = ref(storage, `/music/${file.name}`);
    const uploadTask = () => uploadBytesResumable(storageRef, file);
    await uploadTask();
  };
  const uploadCover = async () => {
    if (!imgcover) {
      alert("Please select a image first!");
    }

    const storageRefCover = ref(storage, `/cover/${imgcover.name}`);
    const uploadTaskCover = () =>
      uploadBytesResumable(storageRefCover, imgcover);
    await uploadTaskCover();
  };

  var sUrl = "";
  var cUrl = "";

  const getURLs = async () => {
    await uploadMusic();
    await uploadCover();

    const storageRef = ref(storage, `/music/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    const storageRefCover = ref(storage, `/cover/${imgcover.name}`);
    const uploadTaskCover = uploadBytesResumable(storageRefCover, imgcover);

    await getDownloadURL(uploadTaskCover.snapshot.ref).then((songCover) => {
      cUrl = songCover;
    });
    await getDownloadURL(uploadTask.snapshot.ref).then((songUrl) => {
      sUrl = songUrl;
    });
    console.log(sUrl + " & " + cUrl);
    await handleUpload(cUrl, sUrl);
    console.log("Done");
    resetAll();
  };

  const handleUpload = async (songCover, songUrl) => {
    await setDoc(doc(db, "music", realID), {
      songName,
      songAuthor,
      songTime,
      songCover,
      songUrl,
      realID,
    });
    uploadSuccess();
  };

  const timeID = Date.now();
  const realID = "id-" + timeID;

  const uploadSuccess = () =>
    toast.success("Upload successfull ✔️", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const [updateListNow, setUpdateListNow] = useState("");
  const reloadMusicList = () => {
    setUpdateListNow(Date.now());
  };

  const resetAll = () => {
    //reset Name
    document.getElementById("inputName").value = "";
    setSongName("");
    //reset Author
    document.getElementById("inputAuthor").value = "";
    setSongAuthor("");
    //reset Time
    document.getElementById("inputTime").value = "";
    setSongTime("");
    //reset Image
    document.getElementById("inputImage").value = "";
    document.getElementById("image-preview").src = place500;
    setImgCover("");
    //reset audio
    document.getElementById("inputAudio").value = "";
    document.getElementById("audio-preview").src = "";
    setFile("");
    //reload MusicList
    reloadMusicList();
  };

  //----------------------------------------
  // Add Playlist
  //----------------------------------------
  const [playListName, setPlayListName] = useState("");
  const addList = async () => {
    await setDoc(doc(db, "playlist", playListName), {
      playListName,
    });
    addedSuccess();
    reloadMusicList();
  };
  const addedSuccess = () =>
    toast.success("Added Playlist ✔️", {
      position: "top-center",
      autoClose: 3500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  //----------------------------------------
  return (
    <div className="Create">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <h1>Add Song</h1>
      <div>
        <img
          id="image-preview"
          src={place500}
          alt=""
          className="img-preview-modal"
        />
        <div>
          <input
            placeholder="Title..."
            id="inputName"
            className="inputUI"
            onChange={(event) => {
              setSongName(event.target.value);
            }}
          />
          <br />
          <br />
          <input
            placeholder="Author..."
            id="inputAuthor"
            className="inputUI"
            onChange={(event) => {
              setSongAuthor(event.target.value);
            }}
          />
          <br />
          <br />
          <input
            placeholder="Duration..."
            id="inputTime"
            className="inputUI"
            onChange={(event) => {
              setSongTime(event.target.value);
            }}
          />
          <br />
          <label>Audio</label>
          <input
            type="file"
            accept=".mp3"
            id="inputAudio"
            onChange={handleChange}
          />
          <br />
          <label>Cover</label>
          <input
            type="file"
            accept=".png, .jpg, .jpeg"
            id="inputImage"
            onChange={handleChangeCover}
          />
        </div>
      </div>
      <br></br>
      <audio id="audio-preview" controls />
      <br />
      <br />
      <button className="add-button buttonUI" onClick={() => getURLs()}>
        ⤷ Add
      </button>
      <button className="buttonUI" onClick={resetAll}>
        ↺
      </button>
      <br />
      <br />
      <h1>Add Playlist</h1>
      <input
        placeholder="Playlistname..."
        className="inputUI"
        type="text"
        onChange={(event) => {
          setPlayListName(event.target.value);
        }}
      />
      <button className="buttonUI inputIU-button" onClick={() => addList()}>
        ⤷ Add
      </button>
      <br />
      <br />
      <MusicList key={updateListNow} />
    </div>
  );
}

export default Create;
