import "./App.css";
import Home from "./pages/Home";
import Create from "./pages/Create";
import Profile from "./pages/Profile";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { React, useState, useEffect } from "react";

import { auth } from "./firebase-config";
import { onAuthStateChanged } from "firebase/auth";

function App() {
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");
  const [icon, setIcon] = useState(localStorage.getItem("icon") || "🌒");
  const toggleTheme = () => {
    if (theme === "light") {
      setTheme("dark");
      setIcon("🌞");
    } else {
      setTheme("light");
      setIcon("🌒");
    }
  };

  useEffect(() => {
    localStorage.setItem("theme", theme);
    localStorage.setItem("icon", icon);
    document.body.className = theme;
    // eslint-disable-next-line
  }, [theme]);

  const linkTo = (link) => {
    window.location = link;
  };

  const [iconLoggedIn, setIconLoggedIn] = useState("👤");

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setIconLoggedIn("👽");
      } else {
        setIconLoggedIn("👤");
      }
    });
  }, []);

  return (
    <div className={`App ${theme}`}>
      <div>
        <h1 onClick={() => linkTo("/")} className="header prevent-select">
          🎶 Music
        </h1>
        <span onClick={() => linkTo("/add")}>
          <svg className="svg-circleplus" viewBox="0 0 100 100">
            <circle
              cx="50"
              cy="50"
              r="45"
              fill="none"
              strokeWidth="7.5"
            ></circle>
            <line x1="32.5" y1="50" x2="67.5" y2="50" strokeWidth="5"></line>
            <line x1="50" y1="32.5" x2="50" y2="67.5" strokeWidth="5"></line>
          </svg>
        </span>
        <button onClick={() => linkTo("/profile")} className="header-person">
          {iconLoggedIn}
        </button>
        <button className="themeToggler" onClick={toggleTheme}>
          {icon}
        </button>
      </div>
      <main>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/add" element={<Create />} />
            <Route path="/profile" element={<Profile />} />
          </Routes>
        </Router>
      </main>
    </div>
  );
}

export default App;
