import { React, useState, useEffect } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import "../darkMode.css";
import { motion, useAnimationControls } from "framer-motion";
// https://random.imagecdn.app/500/500
import { db } from "../firebase-config";
import { collection, getDocs } from "firebase/firestore";
import loadingImg from "../img/loading.png";

function Home() {
  const [playlist, setPlaylist] = useState([
    {
      songName: "Loading...",
      songUrl: "",
      songAuthor: "Loading...",
      songCover: loadingImg,
    },
  ]);
  const [backupList, setBackupList] = useState([
    {
      songName: "Loading...",
      songUrl: "",
      songAuthor: "Loading...",
      songCover: loadingImg,
    },
  ]);
  const [gplayList, gsetPlayList] = useState([]);

  const getData = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "music"));
      setPlaylist([]);
      setBackupList([]);
      querySnapshot.forEach((doc) => {
        setPlaylist((oldArray) => [...oldArray, doc.data()]);
        setBackupList((oldArray) => [...oldArray, doc.data()]);
      });
      console.log("Did request! -> Music");
    } catch (error) {
      console.log(error);
    }
  };

  const getPlaylist = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "playlist"));
      gsetPlayList([]);
      querySnapshot.forEach((doc) => {
        gsetPlayList((oldArray) => [...oldArray, doc.data()]);
      });
      console.log("Did request! -> Playlist");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
    getPlaylist();
  }, []);

  const controls = useAnimationControls();
  const [currentTrack, setTrackIndex] = useState(0);
  const songname = playlist[currentTrack].songName;
  const authorname = playlist[currentTrack].songAuthor;
  const imgcover = playlist[currentTrack].songCover;
  // const imgcover = "https://i.imgur.com/A9oVFbP.png";

  navigator.mediaSession.metadata = new MediaMetadata({
    title: songname,
    artist: authorname,
    album: "cosk.io",
    artwork: [
      {
        src: imgcover,
        sizes: "500x500",
        type: "image/png",
      },
    ],
  });

  const handleClickNext = () => {
    console.log("click next");
    controls.start({ scale: [1, 1.1, 1] });
    setTrackIndex((currentTrack) =>
      currentTrack < playlist.length - 1 ? currentTrack + 1 : 0
    );
  };

  const handleClickPrevious = () => {
    console.log("click previous");
    setTrackIndex((currentTrack) =>
      currentTrack > playlist.length - 1 ? currentTrack - 1 : 0
    );
  };

  const handleEnd = () => {
    console.log("end");
    setTrackIndex((currentTrack) =>
      currentTrack < playlist.length - 1 ? currentTrack + 1 : 0
    );
  };

  const changeTrack = (track) => {
    console.log("change track to:" + track);
    setTrackIndex(track);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  //--------------------------------------
  // SetActiveTrack
  //--------------------------------------

  const changeActiveTrackNow = () => {
    for (let i = 0; i < playlist.length; i++) {
      const removeClass = document.getElementById(i);
      removeClass.classList.remove("next-active");
    }
    const element = document.getElementById(currentTrack);
    element.classList.add("next-active");
  };

  useEffect(() => {
    changeActiveTrackNow();
    // eslint-disable-next-line
  }, [currentTrack]);
  //--------------------------------------

  // Key Shortcuts
  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === "p") {
        document
          .getElementsByClassName(
            "rhap_button-clear rhap_main-controls-button rhap_play-pause-button"
          )[0]
          .click();
      }
      if (event.key === "n") {
        document
          .getElementsByClassName(
            "rhap_button-clear rhap_main-controls-button rhap_skip-button"
          )[1]
          .click();
      }
    }

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const changePlaylist = async (inputFunction) => {
    const getIndex = gplayList.findIndex(
      (x) => x["playListName"] === inputFunction
    );

    setPlaylist([]);
    setTrackIndex(0);
    await gplayList[getIndex].songs.forEach((data) => {
      //actuallyPlaylist.push(playlist.filter((x) => x["realID"] === data)[0])
      setPlaylist((oldArray) => [
        ...oldArray,
        backupList.filter((x) => x["realID"] === data)[0],
      ]);
    });
    setTrackIndex(0);
  };

  const getAllMusic = () => {
    setPlaylist(backupList);
    setTrackIndex(0);
  };

  const onChange = (event) => {
    const value = event.target.value;
    if (value === "all") {
      getAllMusic();
    } else {
      changePlaylist(value);
    }
  };

  return (
    <div className="Home prevent-select">
      <motion.div animate={controls} className="center-screen-img">
        <img
          src={imgcover}
          alt=""
          height={500}
          width={500}
          className="imgcover"
        />
      </motion.div>
      <div className="center-screen-text">
        <p className="songTitle">{songname}</p>
        <p className="songAuthor">{authorname}</p>
      </div>

      <AudioPlayer
        autoPlay
        volume="0.5"
        src={playlist[currentTrack].songUrl}
        showSkipControls
        onClickNext={handleClickNext}
        onClickPrevious={handleClickPrevious}
        onEnded={handleEnd}
      />
      <select
        defaultValue={"all"}
        onChange={onChange}
        className="select-default"
      >
        <option value="all">All</option>
        {gplayList.map((play) => {
          return (
            <option key={play.playListName} value={play.playListName}>
              {play.playListName}
            </option>
          );
        })}
      </select>
      <div className="next-wrapper">
        {playlist.map((data, index) => (
          <div
            key={data.songName}
            // className="next-container nextSong next-active"
            className={
              index === 0
                ? "next-container nextSong next-active"
                : "next-container nextSong"
            }
            onClick={() => changeTrack(index)}
            id={index}
          >
            <img
              src={data.songCover}
              width={100}
              height={100}
              alt={data.songName}
              className="imgcover next-image"
            />
            <div className="next-content">
              <div className="next-items">{data.songName}</div>
              <div className="next-items">{data.songAuthor}</div>
              <div className="next-items last">{data.songTime}</div>
            </div>
          </div>
        ))}
      </div>
      <br />
    </div>
  );
}

export default Home;
